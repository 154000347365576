/* Branding on the banner */
a#CybotCookiebotDialogPoweredbyCybot,
div#CybotCookiebotDialogPoweredByText {
    display: none !important;
}

/* Branding on the Privacy trigger */
#CookiebotWidget .CookiebotWidget-body .CookiebotWidget-main-logo {
    display: none !important;
}

#CybotCookiebotDialogPoweredbyLink {
    justify-content: center !important;
    width: 100% !important;
}

#CybotCookiebotDialog h2 {
    font-weight: normal !important;
}

#CybotCookiebotDialogNav .CybotCookiebotDialogNavItemLink {
    font-weight: normal !important;
    text-transform: uppercase !important;
}

#CybotCookiebotDialogFooter #CybotCookiebotDialogBodyButtonsWrapper button {
    font-weight: normal !important;
    text-transform: uppercase !important;
}

#CybotCookiebotDialog .CookieCard .CybotCookiebotDialogDetailBodyContentCookieContainerButton,
#CybotCookiebotDialog .CookieCard .CybotCookiebotDialogDetailBodyContentIABv2Tab {
    font-weight: normal !important;
    text-transform: uppercase !important;
}

#CybotCookiebotDialogDetailBodyContentCookieContainerTypes .CybotCookiebotDialogDetailBodyContentCookieProvider {
    font-weight: normal !important;
}

#CybotCookiebotDialog #CybotCookiebotDialogBodyContentText a,
#CybotCookiebotDialog #CybotCookiebotDialogBodyLevelButtonIABHeaderViewPartnersLink,
#CybotCookiebotDialog #CybotCookiebotDialogDetailBulkConsentList dt a,
#CybotCookiebotDialog #CybotCookiebotDialogDetailFooter a,
#CybotCookiebotDialog .CybotCookiebotDialogBodyLevelButtonIABDescription a,
#CybotCookiebotDialog .CybotCookiebotDialogDetailBodyContentCookieLink,
#CybotCookiebotDialogDetailBodyContentTextAbout a {
    font-weight: normal !important;
}

#CybotCookiebotDialogTabContent .CybotCookiebotDialogDetailBulkConsentCount {
    background-color: unset !important;
    font-size: inherit !important;
    font-weight: normal !important;
    padding: 0 !important;
    margin-left: .1em !important;
}

#CybotCookiebotDialogTabContent .CybotCookiebotDialogDetailBulkConsentCount:before {
    content: "(" !important;
}

#CybotCookiebotDialogTabContent .CybotCookiebotDialogDetailBulkConsentCount:after {
    content: ")" !important;
}

#CybotCookiebotDialog .CybotCookiebotScrollContainer {
    padding: 1em 1em 0 1em !important;
}

.CybotCookiebotDialogDetailBodyContentCookieContainerButton:before,
.CybotCookiebotDialogDetailBodyContentIABv2Tab:before {
    border-width: .10em .10em 0 0 !important;
    margin-right: 0.8em !important;
}

.CybotCookiebotDialogDetailBodyContentCookieContainerButton:before,
.CybotCookiebotDialogDetailBodyContentIABv2Tab:before {
    top: 0.4em !important;
}

#CybotCookiebotDialogDetailFooter {
    display: none !important;
}

#CybotCookiebotDialog .CybotCookiebotScrollContainer {
    border-bottom: none !important;
}

#CybotCookiebotDialog .CookieCard {
    border-bottom: 1px solid #333333 !important;
}

#CybotCookiebotDialogNav .CybotCookiebotDialogNavItemLink.CybotCookiebotDialogActive {
    border-bottom: 1px solid #000000 !important;
}

#CybotCookiebotDialogFooter #CybotCookiebotDialogBodyLevelButtonCustomize .CybotCookiebotDialogArrow {
    display: none !important;
}

#CybotCookiebotDialogPoweredbyImage {
    max-height: 2.125em !important;
}

#CybotCookiebotDialog {
    border-radius:0;
}

#CybotCookiebotDialogHeader {
    border-bottom:1px solid black;
}

#CybotCookiebotDialogFooter .CybotCookiebotDialogBodyButton {
    border-radius:0;
}

@media screen and (min-width: 601px) {
    #CybotCookiebotDialog {
        max-width: 600px !important;
    }
}

@media screen and (min-width: 1280px) {
    #CybotCookiebotDialogHeader {
        padding: 1em !important;
    }
}


